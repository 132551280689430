<template>
  <KTCodePreview v-bind:title="formTitle">
    <template v-slot:preview>
      <div>
        <b-card>
          <b-form class="full-width" @submit="onSubmit">
            <b-form-group
              label="Tên Campaign:"
              label-for="input-campaign"
              description="Điền campaign mà bạn muốn nhập tại đây"
            >
              <b-form-input
                v-model.trim="form.name"
                type="text"
                required
                placeholder="Điền tên Campaign"
                :state="!$v.form.name.$dirty ? null : !$v.form.name.$anyError"
                @blur="$v.form.name.$touch()"
                size="sm"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!$v.form.name.required">
                Bạn chưa nhập tên Campaign
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.name.minLength">
                Tên Campaign của bạn cần dài hơn
                {{ MIN_CAMPAIGN_NAME_LENGTH }} ký tự
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.name.maxLength">
                Tên Campaign của bạn cần ngắn hơn
                {{ MAX_CAMPAIGN_NAME_LENGTH }} ký tự
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row class="mb-4">
              <b-col sm="6">
                <b-form-group
                  id="input-start-date"
                  label="Thời gian bắt đầu:"
                  label-for="input-start-date"
                  class="mb-1"
                  :state="
                    !$v.form.startDate.$dirty
                      ? null
                      : !$v.form.startDate.$anyError
                  "
                >
                  <date-picker
                    id="input-start-date"
                    placeholder="Thời gian bắt đầu"
                    :class="
                      `form-control ${
                        $v.form.startDate.$dirty && $v.form.startDate.$anyError
                          ? 'form-input-error'
                          : ''
                      }`
                    "
                    :config="dpConfigs.date"
                    required
                    v-model.trim="form.startDate"
                    autosuggest="off"
                    @blur.native="$v.form.startDate.$touch()"
                    size="sm"
                  ></date-picker>
                </b-form-group>
                <b-form-invalid-feedback v-if="!$v.form.startDate.required">
                  Bạn chưa nhập thời gian bắt đầu
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.startDate.$model &&
                      !$v.form.startDate.isValidDateTime
                  "
                >
                  Thời gian bắt đầu không hợp lệ
                </b-form-invalid-feedback>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="input-end-date"
                  label="Thời gian kết thúc"
                  label-for="input-end-date"
                  class="mb-1"
                  :state="
                    !$v.form.endDate.$dirty ? null : !$v.form.endDate.$anyError
                  "
                >
                  <date-picker
                    id="input-end-date"
                    placeholder="Thời gian kết thúc"
                    :class="
                      `form-control ${
                        $v.form.endDate.$dirty && $v.form.endDate.$anyError
                          ? 'form-input-error'
                          : ''
                      }`
                    "
                    :config="dpConfigs.date"
                    required
                    v-model.trim="form.endDate"
                    @blur.native="$v.form.endDate.$touch()"
                    autosuggest="off"
                    size="sm"
                  ></date-picker>
                </b-form-group>
                <b-form-invalid-feedback v-if="!$v.form.endDate.required">
                  Bạn chưa nhập thời gian kết thúc
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="
                    $v.form.endDate.$model && !$v.form.endDate.isValidDateTime
                  "
                >
                  Thời gian kết thúc không hợp lệ
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-button
              type="submit"
              variant="primary"
              @click="onSubmit"
              :disabled="!!$v.$invalid || isSubmitting"
            >
              Lưu
            </b-button>
          </b-form>
        </b-card>
      </div>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import { DP_CONFIG_DATETIME, formatDate } from '@/utils/date';
import ApiService from '@/core/services/api.service';
import {
  disableSubmitButton,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';
import { DATETIME_FORMAT, SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { cmdUrl } from '@/utils/apiUrl';
import { validationMixin } from 'vuelidate';
import {
  MAX_CAMPAIGN_NAME_LENGTH,
  MIN_CAMPAIGN_NAME_LENGTH,
  validateCampaign,
} from '@/view/pages/posm/validations/validate-campaign.mixins';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

export default {
  components: {
    KTCodePreview,
  },
  mixins: [validationMixin, validateCampaign],
  data() {
    return {
      form: {
        name: null,
        startDate: null,
        endDate: null,
      },
      isSubmitting: false,
      dpConfigs: DP_CONFIG_DATETIME,
      MIN_CAMPAIGN_NAME_LENGTH,
      MAX_CAMPAIGN_NAME_LENGTH,
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.isSubmitting = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.isSubmitting = false;
        console.log(this.$v);
        return;
      }
      if (!this.$route.params.id) {
        ApiService.post(`${cmdUrl.Campaign.root}`, this.form)
          .then(({ data }) => {
            const { status, message } = data;
            if (status === SUCCESS_RESPONSE_STATUS) {
              makeToastSuccess(message);
              this.$router.push({ name: 'posm-form-campaigns' });
            }
          })
          .catch((err) => {
            makeToastFaile(err.response.data.message);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        ApiService.put(
          `${cmdUrl.Campaign.root}/${this.$route.params.id}`,
          this.form,
        )
          .then(({ data }) => {
            const { status, message } = data;
            if (status === SUCCESS_RESPONSE_STATUS) {
              makeToastSuccess(message);
              this.$router.push({ name: 'posm-form-campaigns' });
            }
          })
          .catch((err) => {
            makeToastFaile(err.response.data.message);
          })
          .finally(() => {
            disableSubmitButton.call(this);
          });
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Quản lý Campaign', route: '/posm/campaigns' },
        { title: 'Cập nhật Campaign' },
      ]);
      ApiService.get(`${cmdUrl.Campaign.root}/${this.$route.params.id}`)
        .then(({ data }) => {
          const { status, data: campaign } = data;
          if (status === SUCCESS_RESPONSE_STATUS) {
            this.form = campaign;

            this.form.startDate = formatDate(
              this.form.startDate,
              DATETIME_FORMAT,
            );
            this.form.endDate = formatDate(this.form.endDate, DATETIME_FORMAT);
          }
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        });
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Quản lý Campaign', route: '/posm/campaigns' },
        { title: 'Tạo Campaign mới' },
      ]);
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id
        ? 'Cập nhật Campaign POSM'
        : 'Tạo Campaign POSM';
    },
  },
};
</script>

<style scoped>
.form-input-error {
  transition: 0.3s all;
  border: 1px solid red;
}
</style>
